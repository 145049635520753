:root {
  --color-primaryColor: #a60726;
  --color-primaryColor-light: #fee7eb;
  --color-iconBackground: #fdced7;
  --color-successColor: #45ae27;
  --color-neutral: #7d7d7d;
  --color-neutralLight: #f5f5f5;
  --color-neutralDark: #4a4a4a;
  --color-neutralSecondary: #9b9b9b;
  --color-neutralDarker: #1a1a1a;
  --color-black: #000000;
  --color-black-70: rgba(0, 0, 0, 0.7);
  --color-secondaryColorLighter: #e0e0e0;
  --color-ternaryColor: #f8f6f6;
  --color-whiteColor: #ffffff;
  --color-secondary: #3633b9;
  --global-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  --color-error: #d0021b;
  --color-statusDone: #9ebd3d;
  --color-actionRealEstate: #f3a648;
  --color-actionProspect: #006e86;
  --color-iconValidationCardBackground: rgb(225,239,242);
  --color-login-label: #000000;

  /* custom Theme portail */
  --color-background-custom: rgba(0, 110, 134, 1);
  --custom-box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13), 0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
  --color-custom-primary: #006e86;
  --font-weight-custom-H1: 800;
  --font-size-custom-H1: 21px;
  --color-custom-secondary: #72767b;
  --color-custom-button: #f3a648;
  --color-custom-button-hover: #f5b363;
  --color-custom-footer: #363636;
  --font-size-custom-small: 10px;

  /*white label theme portail */
  --color-background-whitelabel: rgba(0, 110, 134, 1);
  --whitelabel-box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13), 0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
  --color-whitelabel-primary: #a60726;
  --color-whitelabel-secondary: #72767b;
  --font-weight-whitelabel-H1: 800;
  --font-weight-whitelabel-regular: 400;
  --font-size-whitelabel-H1: 26px;
  --font-size-whitelabel-regular: 12px;
  --font-size-whitelabel-title: 18px;
  --font-weight-whitelabel-title: 700;
  --color-whitelabel-button: #f3a648;
  --color-whitelabel-button-hover: #f5b363;
  --font-size-whitelabel-link: 12px;
  --color-whitelabel-link: #a60726;
  --color-whitelabel-link-hover: #a60726;
  --font-size-whitelabel-small: 10px;
  --color-whitelabel-footer:#000000;
}
